import React from "react";
import Layout from "../../components/Layout";
import Helmet from "react-helmet";
import Iframe from "react-iframe";

const title = "Sign Up | Amos Institute";
const description =
  "Starting your new program takes less than 2 minutes! Use this form to create an Amos Institute account and start your Cognitive Health Program today.";

const SignUp = () => {
  return (
    <>
      <Layout>
        <Helmet title={title}>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <section className="signup container mx-auto">
          <div style={{ height: "800px" }}>
            <Iframe
              url="https://hipaa.jotform.com/203126894390054"
              width="100%"
              height="100%"
            />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SignUp;
